.container {
    padding: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100%;
}

.cardLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: rgb(58, 55, 55);
}

.cardsError {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: red;
}