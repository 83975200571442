.cardTitle {
    font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    font-weight: 800;
    text-rendering: optimizeLegibility;
    font-size: 1.5rem;
    color: rgb(32, 35, 41);
}

.cardSubtitle {
    font-weight: bold;
    font-size: large;
    color: rgb(54, 49, 49);
}

.firstSeen {
    font-size: 16px;
    font-weight: 500;
    color: rgb(88, 88, 88);
}

.cardText {
    color: rgb(51, 47, 47);
    font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica','Arial',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
}


